<template>
	<div
		data-qa="petHeaderCard_card_petInfo"
	>
		<aq-care-flag
			v-if="careFlags.length"
			:care-flags="careFlags"
		/>
		<item-heading
			class="gap-m"
			:heading="pet.name"
			heading-class="mb-1 pet-name text-truncate"
			:paragraph="pet.breedName"
			paragraph-class="text-truncate"
		>
			<router-link
				:to="customerId ? { name: 'pet', params: { customerId: customerId, petId: pet.id } } : ''"
				exact-active-class="current-route"
			>
				<aq-pet-avatar
					:pet="{
						name: pet.name,
						type: pet.type,
						gender: pet.gender,
						dateOfDeath: pet.dateOfDeath
					}"
					class="cursor-inherit"
				/>
			</router-link>
			<template slot="rightArea">
				<div class="right-area">
					<i
						class="fas"
						:class="[isMale ? 'fa-mars' : 'fa-venus']"
						v-tooltip="isMale ? 'Male' : 'Female'"
					/>
					<medical-record-summary-button
						:underwriting-status-id="pet.underwritingStatusId"
						:underwriting-status-description="pet.underwritingStatusDescription"
						:pet-id="pet.id"
					/>
					<aq-list-items-popover
						v-if="pets && pets.length > 1"
						label="Switch Pet"
						:items="pets"
						item-label="name"
						:selected-item="pet"
						@input="onSelectPet"
					>
						<PetSwitcher class="pet-switcher" />
					</aq-list-items-popover>
				</div>
			</template>
		</item-heading>
	</div>
</template>

<script>
import { getCareFlags } from '@clientCommon/services/models/CareFlagEnum';
import PetGender from '@commonServices/models/PetGender';
import eventBus from '@commonServices/eventBus';
import AqListItemsPopover from '@commonWidgets/AqListItemsPopover';
import { PetSwitcher } from '@assets/icons';

export default {
	components: { AqListItemsPopover, PetSwitcher },
	props: {
		pet: {
			type: Object,
			required: true,
		},
		pets: {
			type: Array,
			required: false,
			default: null,
		},
		customerId: {
			type: Number,
			required: false,
			default: null,
		},
	},
	mounted () {
		eventBus.$on('pet-careFlags-updated', this.onPetCareFlagsUpdated);
	},
	beforeDestroy () {
		eventBus.$off('pet-careFlags-updated', this.onPetCareFlagsUpdated);
	},
	computed: {
		careFlags () {
			return getCareFlags(this.pet.careFlag);
		},
		isMale: function () {
			return this.pet.gender === PetGender.Male;
		},
	},
	methods: {
		onSelectPet (pet) {
			if (pet.id === this.pet.id) return;
			this.$router.push({ name: 'pet', params: { customerId: this.customerId, petId: pet.id } });
		},
		onPetCareFlagsUpdated (careFlags) {
			this.pet.careFlag = careFlags;
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .pet-name {
  font-size: 24px;
  letter-spacing: -0.04em;
  font-weight: bold;
}

.fa-venus {
  color: var(--cardIconColour);
  font-size: 30px;
}

.fa-mars {
  font-size: 30px;
  color: $primary;
  text-align: center;
}

.card-icon {
  position: relative;
}

.switch-pet {
  margin-right: 50px;
}

.right-area {
  display: flex;
  flex-grow: 2;
  gap: 14px;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 4.8rem;

  .pet-switcher {
    cursor: pointer;
    height: 42px;
    width: 42px;
  }

  svg, ::v-deep .paw-print-icon {
    height: 35px;
    width: 35px;

    &:hover {
      transform: scale(1.1);
    }
  }

  .fas {
    font-size: 33px;

    &:hover {
      transform: scale(1.1);
    }
  }
}
</style>
