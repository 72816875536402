<template>
	<div>
		<aq-confirmation-modal
			ref="warningModal"
			name="reassess-warning-modal"
			title="Claims Reassessment"
			description="You are about to reassess a claim, any changes made will effect existing claim data and claim calculation"
			question="Are you sure you wish to continue?"
			yes-label="Proceed"
			no-label="Cancel"
		/>
		<aq-modal name="reassess-claim">
			<ManualReassessmentModal
				@reassess-claim="onReassess"
				data-qa="petClaims_modal_reassessClaim"
				:is-parent="isParent(selectedReassessClaim)"
			/>
		</aq-modal>
		<div class="section-header position-relative p-0 justify-content-center flex-nowrap">
			<AqFlagCard
				v-if="createClaimAvailable"
				:class="{ 'continuation-claim': isContinuatonClaim }"
				class="create-claim-flag"
			>
				<button
					class="button"
					@click="$emit('create-claim')"
				>
					{{ isContinuatonClaim ? 'Create Continuation Claim' : 'Create Claim' }}
				</button>
			</AqFlagCard>
			<div class="d-flex claim-view-header">
				<div
					:class="{ 'active': claimViewMode === $options.ClaimViewModeOptions.List && !filtersOpened }"
					class="claim-view-icon"
					@click="onToggleClaimView($options.ClaimViewModeOptions.List)"
					v-tooltip="'List View'"
					data-qa="petClaims_button_listView"
				>
					<ClaimListView />
				</div>
				<div
					:class="{ 'active': claimViewMode === $options.ClaimViewModeOptions.Group && !filtersOpened }"
					class="claim-view-icon"
					@click="onToggleClaimView($options.ClaimViewModeOptions.Group)"
					v-tooltip="'Group View'"
					data-qa="petClaims_button_groupView"
				>
					<ClaimGroupView />
				</div>
				<aq-header-toggler
					class="filters align-self-stretch"
					@on-state-changed="filtersOpened=$event"
				>
					<template #toggler="props">
						<div
							@click="props.toggleState(!filtersOpened)"
							class="claim-view-icon"
							:class="{ 'active': filtersOpened }"
						>
							<FilterIcon />
						</div>
					</template>
					<template #default="props">
						<div class="section-header filter-header py-0">
							Filters & Sorting
						</div>
						<claims-filter
							data-qa="petClaims_modal_filter"
							:filters="filters"
							:sorting="sorting"
							@apply-filters="props.closeContent(); onApplyFilters($event)"
							@clear-filters="props.closeContent(); onClearFilters()"
						/>
					</template>
				</aq-header-toggler>
				<div
					v-if="showToggleGraphButton"
					class="fs-22"
				>
					<i
						v-tooltip="'View Claims Graphs'"
						class="fas fa-chart-column claim-view-icon"
						@click="toggleGraphViewMode"
					/>
				</div>
			</div>
		</div>
		<div
			class="position-relative"
			v-if="filterTerms.length"
		>
			<div class="filter-terms d-flex flex-wrap">
				<aq-search-term
					v-for="term of filterTerms"
					:header="term.label"
					:key="term.key"
					:value="term.value"
					:removable="term.removable"
					header-class="text-primary"
					class="px-10 mr-5 mb-5 py-6 search-term__rounded align-items-center"
					@remove="onRemoveSearchTerm(term)"
					:data-qa="`petClaims_searchTerm_${term.key}`"
				/>
			</div>
		</div>
		<div
			class="section-content position-relative"
			id="pet-claims-container"
			v-if="activePetId"
			v-shortkey="$shortkeys.select"
			@shortkey="onClaimShortkeysHandle"
		>
			<div class="container-fluid p-0">
				<aq-list-navigation-control
					:list="navigationClaimsList"
					@current-changed="onSelectClaim"
					:current-item="currentItem"
					id="pet-claims-container"
				>
					<AqBackToTop
						v-if="isListViewMode"
						:key="claimViewMode"
						selector=".el-table__body-wrapper"
					>
						<claims-table
							class="scrollbar-transparent"
							data-qa="petClaims_table_claims"
							table-height="70vh"
							:claims="petClaims"
							:on-select-claim="onSelectClaim"
							:on-select-option="onSelectOption"
							:get-row-class="getRowClass"
							:current-item="currentItem"
							:brand="brand"
							:show-extra-columns="showExtraColumns"
							boundaries-element-selector="#pet-claims-container"
						/>
					</AqBackToTop>
					<AqBackToTop
						:key="claimViewMode"
						v-else
					>
						<div class="max-height pt-8 px-8">
							<div
								v-for="table in petGroupedClaims"
								:key="table.id"
								:class="['table-container mb-25', { 'continuation-table': continuationClaimConditionId === table.continuationClaimConditionId }]"
							>
								<div class="d-flex align-items-center title position-relative">
									<div class="flex-shrink-0 mr-20 ml-10">
										<b>DOL:</b>&nbsp;{{ table.dateOfLoss | shortDateNoCentury }}
									</div>
									<div
										class="d-flex text-truncate group-diagnosis"
										@click="onSelectContinuationClaim(table.continuationClaimId, table.continuationClaimConditionId, table.continuationClaimStatus, table.id)"
									>
										<div class="mr-15 circle-badge">
											{{ table.claims.length }}
										</div>
										<strong>{{ table.diagnosis }}</strong>
									</div>
									<div
										class="group-claims"
										:class="{ 'disabled': table.claims.length <= 1 }"
										@click="onTableGroupClick(table.id)"
									>
										<i
											class="fa-solid py-5 text-center"
											:class="[ isGroupOpen(table.id) ? 'fa-chevron-down' : 'fa-chevron-right']"
										/>
									</div>
								</div>
								<claims-table
									class="scrollbar-transparent"
									data-qa="petClaims_table_claims"
									:claims="getVisibleClaims(table.claims, isGroupOpen(table.id))"
									:on-select-claim="onSelectClaim"
									:on-select-option="onSelectOption"
									:get-row-class="getRowClass"
									:brand="brand"
									:current-item="currentItem"
									:show-extra-columns="showExtraColumns"
									:allow-ungroup="allowUngroup"
									show-parent-icon
									boundaries-element-selector="#pet-claims-container"
								/>
							</div>
						</div>
					</AqBackToTop>
				</aq-list-navigation-control>
			</div>
			<div
				v-if="filtersOpened"
				class="position-absolute blurred"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import eventBus from '@commonServices/eventBus';
import rowHighlightMixin from '@mixins/rowHighlightMixin';
import ClaimViewMode from '@commonServices/settings/ClaimViewMode';
import ClaimsTable from '@commonView/CustomerPage/PetsLayout/ClaimsTable';
import ClaimsFilter from '@commonView/Shared/ClaimsFilter';
import { FilterType } from '@commonServices/models/FilterType';
import { ClaimListView, ClaimGroupView, FilterIcon } from '@assets/icons';
import { sortComparer, uniqueBy } from '@commonServices/utils/general';
import SortingDirection from '@commonServices/models/SortingDirection';
import { ClaimFilterEnum, ClaimFilterDisplayMap } from '@commonServices/models/ClaimFilterEnum';
import FilterComponentTypeEnum from '@commonServices/models/FilterComponentTypeEnum';
import ClaimService from '@commonServices/claimService';
import ExtraService from '@commonServices/extraService';
import ManualReassessmentModal from '@commonView/ClaimPage/Shared/ManualReassessmentModal';
import WorkflowService from '@commonServices/workflowService';
import { finalClaimDisplayStatuses, readOnlyClaimStates } from '@commonServices/models/ClaimStatusActionEnum';
import { claimSourceDisplayMap } from '@clientCommon/services/models/ClaimSource';
import AqBackToTop from '@commonView/Shared/AqBackToTop';
import SortingColumn from '@commonServices/models/SortingColumn';
import AqFlagCard from '@commonWidgets/AqFlagCard';

export default {
	components: {
		ClaimsTable,
		ClaimsFilter,
		AqBackToTop,
		ClaimListView,
		ClaimGroupView,
		FilterIcon,
		ManualReassessmentModal,
		AqFlagCard,
	},
	mixins: [rowHighlightMixin],
	props: {
		pet: {
			type: Object,
			required: true,
		},
		selectedClaimId: {
			type: Number,
			required: false,
			default: null,
		},
		showToggleGraphButton: {
			type: Boolean,
			required: true,
		},
		currentPolicyTerm: {
			type: Object,
			required: false,
		},
	},
	ClaimViewModeOptions: ClaimViewMode,
	data () {
		return {
			rawGroupedClaims: [],
			groupedClaimsState: [],
			currentClaimConditionId: null,
			continuationClaimId: null,
			continuationClaimConditionId: null,
			selectedItems: [],
			filtersOpened: false,
			filters: [],
			sorting: {
				value: null,
				direction: SortingDirection.Ascending,
				columns: [
					new SortingColumn('Ailment', 'diagnosis'),
					new SortingColumn('Treatment Start', 'treatmentStart'),
					new SortingColumn('Date Submitted', 'createdDateTime'),
				],
			},
			sortingFunc: this.claimsDefaultSortComparer,
			rawClaims: [],
			reassessmentData: {
				reasssessmentReasonId: 0,
			},
			selectedReassessClaim: null,
		};
	},
	async mounted () {
		const [{ ClaimStatus: claimStatuses }] = await Promise.all([
			ExtraService.getFiltersData([FilterType.ClaimStatus]),
			this.loadPetClaims(),
		]);

		this.claimStatuses = claimStatuses;

		this.initFilters();

		if (this.selectedClaimId) {
			const claim = this.rawClaims.find((item) => item.claimId === this.selectedClaimId);
			this.onSelectGraphSegmentHandler(claim.claimConditionId);
		}

		this.changeClaimContext();

		eventBus.$on('select-graph-segment', this.onSelectGraphSegmentHandler);
	},
	beforeDestroy () {
		eventBus.$off('select-graph-segment', this.onSelectGraphSegmentHandler);
	},
	watch: {
		currentPolicyTerm () {
			this.clearCurrentItemHandler();
		},
	},
	computed: {
		...mapState(['levelFilter', 'brand', 'appSettings']),
		...mapGetters(['isClaimContext', 'claimViewMode']),
		activePetId () {
			return this.pet.id;
		},
		isListViewMode () {
			return this.claimViewMode === ClaimViewMode.List;
		},
		showExtraColumns () {
			return this.appSettings.toggleGraphView;
		},
		allowUngroup () {
			return this.appSettings.allowUngroup && !this.isListViewMode && this.$can.UngroupClaim;
		},
		navigationClaimsList () {
			if (this.isListViewMode) {
				return this.petClaims;
			} else if (this.continuationClaimConditionId) {
				return this.claimReducer(this.petGroupedClaims.filter((claim) => claim.continuationClaimConditionId === this.continuationClaimConditionId));
			} else {
				return this.claimReducer(this.petGroupedClaims);
			}
		},
		isCurrentItemReadOnly () {
			return readOnlyClaimStates.includes(this.currentItem?.status);
		},
		createClaimAvailable () {
			return this.pet && this.$can[this.continuationClaimId ? 'ClaimEditContinuation' : 'ClaimEdit'];
		},
		isContinuatonClaim () {
			return this.continuationClaimId && !this.brand.multicondition;
		},
		petClaims () {
			if (!this.isListViewMode || this.filters.length === 0 || this.rawClaims.length === 0) return [];

			let petClaims = [...this.rawClaims];
			for (const selectedFilter of this.filters.filter(f => f.visible && f.value !== null)) {
				petClaims = petClaims.filter(selectedFilter.filterFunction(selectedFilter.value, true));
			}

			petClaims.sort(this.sortingFunc);

			return petClaims;
		},
		petGroupedClaims () {
			if (this.isListViewMode || this.filters.length === 0 || this.rawGroupedClaims.length === 0) return [];

			let petGroupedClaims = this.rawGroupedClaims.map(group => ({ ...group, claims: [...group.claims] }));
			const showAllClaimsInGroup = this.filters.find(f => f.key === ClaimFilterEnum.ShowAllClaimsInGroup).value;

			for (const selectedFilter of this.filters.filter(f => f.visible && f.value !== null)) {
				if (showAllClaimsInGroup) {
					// show all groups where at least one claim match the specified filter
					petGroupedClaims = petGroupedClaims.filter(group => group.claims.some(selectedFilter.filterFunction(selectedFilter.value, false)));
				} else {
					petGroupedClaims = petGroupedClaims.map(group => {
						group.claims = group.claims.filter(selectedFilter.filterFunction(selectedFilter.value, false));
						return group;
					}).filter(group => group.claims.length);
				}
			}

			petGroupedClaims.forEach(group => (group.claims = group.claims.sort(this.sortingFunc)));
			return petGroupedClaims;
		},
		filterTerms () {
			return this.filters.filter(f => f.visible && (f.value || f.defaulted))
				.map(f => ({
					key: f.key,
					label: ClaimFilterDisplayMap[f.key],
					type: f.type,
					value: f.type === FilterComponentTypeEnum.Checkbox ? (f.value ? 'Yes' : 'No') : f.value.description,
					removable: !!f.value,
				}));
		},
	},
	methods: {
		...mapActions(['changeInteractionContext', 'updateClaimViewMode']),
		async loadPetClaims () {
			const { groupedClaims, plainClaimsList } = await ClaimService.getPetClaims(this.activePetId);
			this.rawGroupedClaims = groupedClaims;
			this.rawClaims = plainClaimsList;
			this.groupedClaimsState = groupedClaims.map(group => ({ id: group.id, isOpen: false }));
		},
		changeClaimContext () {
			this.changeInteractionContext(
				{
					pet: this.pet,
					claim: this.currentItem ? {
						id: this.currentItem.claimId,
						status: this.currentItem.status,
						isReadOnly: this.isCurrentItemReadOnly,
						registeredVets: this.currentItem.claimVets,
						continuation: this.currentItem.continuation,
					} : null,
				});
		},
		initFilters () {
			const ailments = uniqueBy(this.rawClaims.map(c => c.conditions.map(cc => cc.ailment)).flat().filter(Boolean), 'id')
				.map(ailment => ({ id: ailment.id, description: ailment.firstCause }));

			const sources = uniqueBy(this.rawClaims, 'source')
				.map(claim => ({ id: claim.source, description: claimSourceDisplayMap[claim.source] }))
				.filter(source => source.description);

			const vets = uniqueBy(this.rawClaims.map(c => c.claimVets).flat(), 'id')
				.map(vet => ({ id: vet.id, description: vet.practiceName }));

			this.filters.push({
				label: 'Show historic claims / conditions',
				value: false,
				filterFunction: (value, listView) => (claim, index, group) => value || (listView ? !claim.historical : group.some(c => !c.historical)),
				visible: true,
				key: ClaimFilterEnum.ShowHistoricClaims,
				type: FilterComponentTypeEnum.Checkbox,
				defaulted: true,
			},
			{
				label: 'Show all claims in group',
				value: false,
				filterFunction: () => _ => true,
				visible: !this.isListViewMode,
				key: ClaimFilterEnum.ShowAllClaimsInGroup,
				type: FilterComponentTypeEnum.Checkbox,
				defaulted: false,
			},
			{
				label: 'Ailment',
				value: null,
				multiselect: false,
				filterFunction: (value, listView) => listView ? claim => claim.conditions.some(cond => cond.ailment?.id === value.id) : claim => claim.ailmentId === value.id,
				options: ailments,
				noResultMessage: 'No Ailments found',
				visible: true,
				key: ClaimFilterEnum.Ailment,
				type: FilterComponentTypeEnum.Select,
				defaulted: false,
			},
			{
				label: 'Claim Status',
				value: null,
				multiselect: false,
				filterFunction: value => claim => claim.displayStatus === value.description,
				options: this.claimStatuses,
				noResultMessage: 'No Claim Status found',
				visible: true,
				key: ClaimFilterEnum.ClaimStatus,
				type: FilterComponentTypeEnum.Select,
				defaulted: false,
			},
			{
				label: 'Claim Source',
				value: null,
				multiselect: false,
				filterFunction: value => claim => claim.source === value.id,
				options: sources,
				noResultMessage: 'No Claim Source found',
				visible: true,
				key: ClaimFilterEnum.ClaimSource,
				type: FilterComponentTypeEnum.Select,
				defaulted: false,
			},
			{
				label: 'Provider',
				value: null,
				multiselect: false,
				filterFunction: value => claim => claim.claimVets.some(vet => vet.id === value.id),
				options: vets,
				noResultMessage: 'No Provider found',
				visible: true,
				key: ClaimFilterEnum.Provider,
				type: FilterComponentTypeEnum.Select,
				defaulted: false,
			});
		},
		onApplyFilters ({ filtersToApply, sortingToApply }) {
			this.filters = filtersToApply;
			this.sorting = sortingToApply;
			const sortingFunc = this.sorting.value ? sortComparer(this.sorting.value.field, this.sorting.direction) : this.claimsDefaultSortComparer;
			this.sortingFunc = sortingFunc;

			// open all groups when applying filters
			this.groupedClaimsState = this.groupedClaimsState.map(group => ({ id: group.id, isOpen: true }));
		},
		onClearFilters () {
			this.sorting.value = null;
			this.sorting.direction = SortingDirection.Ascending;
			for (const filter of this.filters) {
				filter.value = filter.type === FilterComponentTypeEnum.Select ? null : false;
			}

			this.sortingFunc = this.claimsDefaultSortComparer;
		},
		onRemoveSearchTerm (term) {
			const value = term.type === FilterComponentTypeEnum.Checkbox ? false : null;
			this.filters.find(filter => filter.key === term.key).value = value;
		},
		onToggleClaimView (view) {
			this.filters.find(filter => filter.key === ClaimFilterEnum.ShowAllClaimsInGroup).visible = view === ClaimViewMode.Group;
			if (this.filtersOpened) return;
			this.updateClaimViewMode(view);
		},
		claimReducer (claims) {
			return claims.reduce((acc, item) => {
				return acc.concat(item.claims.filter(claim => claim.claimId));
			}, []);
		},
		getVisibleClaims (claims, isOpen) {
			// VCL-131: If Group is open return all claims else return first
			return isOpen ? claims : claims.slice(0, 1);
		},
		setGroupedClaimsState (id, value) {
			const claimGroup = this.petGroupedClaims.find(group => group.id === id);
			if (!id || claimGroup.claims.length <= 1) {
				return;
			}

			this.groupedClaimsState.find(group => group.id === id).isOpen = value;
			// if group is closing and selected claim not first in the group, unselect claim
			if (!value && claimGroup.claims.findIndex(claim => claim.claimId === this.currentItem?.claimId) > 0) {
				this.onUnselectClaim();
			}
		},
		isGroupOpen (groupId) {
			return this.groupedClaimsState.find(group => group.id === groupId).isOpen;
		},
		getRowClass ({ row }) {
			if (this.isClaimContext) {
				// if list view mode we use Claim ID to highlight row
				if (this.isListViewMode) {
					if (this.currentItem?.claimId === row.claimId
					|| (!this.currentItem && this.selectedItems.map(item => item.claimId).includes(row.claimId))) {
						return 'border-highlighted';
					}
				// otherwise we use Claim Condition ID
				} else if (this.currentItem?.claimConditionId === row.claimConditionId
				|| (!this.currentItem && this.selectedItems.map(item => item.claimConditionId).includes(row.claimConditionId))) {
					return 'border-highlighted';
				}
			}

			return 'list-item--bg cursor-pointer';
		},
		onClaimShortkeysHandle () {
			if (this.currentItem) {
				const { continuationClaimId, continuationClaimConditionId, continuationClaimStatus, id }
					= this.petGroupedClaims.find((claimGroup) => this.currentItem === claimGroup || claimGroup.claims.includes(this.currentItem))
					|| {};
				this.onSelectContinuationClaim(continuationClaimId, continuationClaimConditionId, continuationClaimStatus, id);
			} else if (this.selectedItems.length) {
				this.onSelectContinuationClaim(this.continuationClaimId, this.continuationClaimConditionId, null, null);
			}
		},
		openClaim (row) {
			this.$router.push({ name: 'claim-page', params: { claimId: row.claimId } });
		},
		onSelectContinuationClaim (continuationClaimId, continuationClaimConditionId, continuationClaimStatus, groupId) {
			this.continuationClaimId = this.continuationClaimConditionId === continuationClaimConditionId ? null : continuationClaimId;
			this.continuationClaimConditionId = this.continuationClaimConditionId === continuationClaimConditionId ? null : continuationClaimConditionId;
			this.currentItem = null;
			this.currentClaimConditionId = null;
			if (this.continuationClaimId) {
				this.selectedItems = this.claimReducer(this.petGroupedClaims.filter((group) => group.continuationClaimConditionId === this.continuationClaimConditionId));
				this.$emit('update-selection-data',
					{
						claimId: this.continuationClaimId,
						claimConditionId: this.continuationClaimConditionId,
						claimIds: this.selectedItems.filter((item) => item.displayStatus === 'Closed-Paid' || item.displayStatus === 'Settled')
							.map(item => item.claimId),
					});
			} else {
				this.currentItem = this.selectedItems[0];
				this.selectedItems = [];
				eventBus.$emit('select-pet-claim', [this.currentItem?.claimId]);
			}
			this.changeClaimContext();

			this.setGroupedClaimsState(groupId, true);

			this.$emit('continue-claim', { claimId: this.continuationClaimId, claimStatus: continuationClaimStatus, continuationGroup: this.selectedItems });
		},
		onSelectClaim (claim) {
			this.currentClaimConditionId = null;
			this.onSelectClaimCondition(claim);
		},
		onSelectClaimCondition (claim, claimConditionId = null) {
			if (!claim) return;

			let isCurrentClaimEqualSelectedClaim = false;
			if (claimConditionId) {
				isCurrentClaimEqualSelectedClaim = this.currentClaimConditionId === claimConditionId;
				this.currentClaimConditionId = claimConditionId;
			} else {
				claimConditionId = claim.claimConditionId;
				isCurrentClaimEqualSelectedClaim = this.currentItem?.claimConditionId === claimConditionId;
			}

			this.currentItem = claim;

			if (!this.selectedItems.find(item => item.claimId === this.currentItem.claimId) && !isCurrentClaimEqualSelectedClaim) {
				this.continuationClaimId = null;
				this.continuationClaimConditionId = null;
				this.$emit('continue-claim', { claimId: this.continuationClaimId, claimStatus: null });
				this.selectedItems = [];
				this.$emit('update-selection-data', { claimId: this.currentItem.claimId, claimConditionId, claimIds: [this.currentItem.claimId] });
				eventBus.$emit('select-graph-conditions', this.currentItem.conditions, claimConditionId);
			}
			this.changeClaimContext();
			if (claim.displayStatus === 'Closed-Paid' || claim.displayStatus === 'Settled') {
				eventBus.$emit('select-pet-claim', [claim.claimId]);
			} else {
				eventBus.$emit('select-pet-claim', []);
			}
		},
		onUnselectClaim () {
			this.currentItem = null;
			this.currentClaimConditionId = null;
			this.changeClaimContext();
		},
		onSelectGraphSegmentHandler (claimConditionId) {
			const groupAndClaim = this.petGroupedClaims.map(group => ({
				group,
				claim: group.claims.find(c => c.claimConditionId === claimConditionId),
			})).find(groupAndClaim => groupAndClaim.claim);

			if (groupAndClaim != null) {
				this.setGroupedClaimsState(groupAndClaim.group.id, true);
				this.onSelectClaim(groupAndClaim.claim);
			} else {
				const claim = this.petClaims.find(c => c.conditions.some(cc => cc.id === claimConditionId));
				this.onSelectClaimCondition(claim, claimConditionId);
			}
			this.scrollToSelectedClaim();
		},
		clearCurrentItemHandler () {
			this.selectedItems = [];
			this.continuationClaimId = null;
			this.continuationClaimConditionId = null;
			this.currentItem = null;
			this.currentClaimConditionId = null;
		},
		async onSelectOption (action, row) {
			switch (action) {
			case 'view':
			case 'edit':
				this.openClaim(row);
				break;
			case 'edit-reject-payment':
				await WorkflowService.rejectSettledClaim(row.claimId, 'reject settled claim');
				this.openClaim(row);
				break;
			case 'edit-reject-pending':
				await WorkflowService.rejectPendingClaim(row.claimId, 'reject pending claim');
				this.openClaim(row);
				break;
			case 'reassess': {
				this.selectedReassessClaim = row;
				await this.$modal.show('reassess-claim');
				break;
			}
			case 'ungroup-condition': {
				await ClaimService.ungroupClaimCondition(row.claimConditionId);
				await this.loadPetClaims();
			}
			}
		},
		async onReassess (value) {
			await ClaimService.reassessClaim(this.selectedReassessClaim.claimId, value.reason);

			if (this.$can.ReassessClaim) {
				this.openClaim({ claimId: this.selectedReassessClaim.claimId });
			} else {
				this.$router.go();
			}
		},
		scrollToSelectedClaim () {
			// scroll to selected claim in claims list
			setTimeout(() => {
				const highlightedRow = this.$el.querySelector('.el-table__row.border-highlighted');
				if (this.isListViewMode) {
					const claimList = this.$el.querySelector('.el-table__body-wrapper');
					claimList?.scrollIntoView();
					claimList?.scroll({
						top: highlightedRow?.offsetTop ?? 0,
						left: 0,
						behavior: 'smooth',
					});
				} else {
					highlightedRow?.scrollIntoView({ block: 'center', behavior: 'smooth' });
				}
			}, 0);
		},
		toggleGraphViewMode () {
			this.$emit('toggle-graph-view');
		},
		onTableGroupClick (groupId) {
			this.setGroupedClaimsState(groupId, !this.isGroupOpen(groupId));
		},
		isParent (claim) {
			return claim?.conditions.some(cc => cc.isParent);
		},
		claimsDefaultSortComparer (a, b) {
			const isFirstOpen = !finalClaimDisplayStatuses.includes(a.displayStatus);
			const isSecondOpen = !finalClaimDisplayStatuses.includes(b.displayStatus);
			if (isFirstOpen && !isSecondOpen) return -1;
			if (!isFirstOpen && isSecondOpen) return 1;
			return (b.treatmentStart - a.treatmentStart);
		},
	},
};
</script>

<style lang="scss" scoped>
.max-height {
  height: 70vh;
  overflow-y: auto;
}

.blurred {
  background: var(--bodyBg);
  opacity: 0.71;
  inset: 0;
  z-index: 15;
}

::v-deep .bg-gray-lighten {
  background-color: darken($gray, 40%);
}

.text-pink {
  color: lighten($pink, 10%);
}

.align-cell-content-end {
  justify-content: flex-end;
}

.filters {
  padding: 0 !important;
}

.filter-terms {
  padding: 15px 20px 0;
}

.search-term__rounded {
  border-radius: 4px;
}

.claim-view-icon {
  height: 100%;
  width: 70px;
  display: flex;
  flex-basis: 70px;
  align-items: center;
  justify-content: center;
  background: var(--headerItemBg);
  fill: var(--sectionTextColour);
  cursor: pointer;

  &:hover, &.active {
    color: var(--cardIconColour);
    fill: var(--cardIconColour);
  }
}

::v-deep .el-table {
  box-shadow: 0 0 10px $box-shadow;
  border-radius: 3px;
  border: 2px solid $primary !important;
}

::v-deep .el-table__body {
  border-spacing: 0 6px;
  margin-top: -5px;
}

::v-deep th {
  > .cell {
    font-weight: 600;
    color: $primary;
  }
}

::v-deep td {
  padding: 0;

  &:last-child .cell {
    padding-right: 0;
  }
}

.continuation-table {
  &.table-container {
    border: 3px solid $active-color;
  }

  ::v-deep .el-table tr td:first-child {
    border-left: none;
  }

  ::v-deep .el-table tr td:last-child {
    border-right: none;
  }

  .group-diagnosis {
    background: $active-color;
    color: #fff;

    &::before {
      border-color: transparent #a94b15 transparent transparent;
    }

    &::after {
      border-color: transparent transparent transparent #a94b15;
    }
  }
}

.claim-view-header {
  position: absolute;
  right: 0;
  height: 65px;
  gap: 2px;
}

::v-deep .header-toggler {
  direction: rtl;

  .header-toggler_content {
    direction: ltr;
    width: 350px;
  }
}

.create-claim-flag {
  position: absolute;
  z-index: 1;

  ::v-deep svg {
    transform: scale(1.2, 1);
  }

  ::v-deep .card {
    margin-top: 14px !important;
  }

  .button {
    margin: 5px -30px;
    height: 40px;
    background-color: var(--headerBg);
    border: 2px solid var(--userIcon);
    border-radius: 6px;
    width: 120px;

    &:hover {
      background-color: var(--logoBg);
    }
  }

  &.continuation-claim {
    ::v-deep svg {
      transform: scale(1.8, 1);
    }

    .button {
      width: 200px;
    }
  }

}

.filter-header {
  min-height: 48px;
  background-color: $primary;
  font-size: 17px;
}

.group-diagnosis {
  position: absolute;
  cursor: pointer;
  box-shadow: $box-shadow 0 5px 8px;
  left: 150px;
  height: 43px;
  min-width: 140px;
  top: -8px;
  align-items: center;
  background: var(--tableTitleCardBg);
  padding: 15px;
  z-index: 1;
  overflow: visible;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 0;
  }

  &::before {
    left: -12.5px;
    border-color: transparent var(--tableTitleTabBg) transparent transparent;
  }

  &::after {
    right: -12.5px;
    border-color: transparent transparent transparent var(--tableTitleTabBg);
  }
}

.table-container {
  background: none;
  border: 3px solid $primary;
  border-radius: 3px;
  box-shadow: 0 0 10px $box-shadow;

  .el-table {
    border: none !important;
    box-shadow: none !important;
  }

  .title {
    background: var(--tableGroupBg);

  }
}

.circle-badge {
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
}

.group-claims {
  text-align: center;
  margin-left: auto;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  color: #fff;
  background: $primary;
  cursor: pointer;
  min-width: 80px;
  font-size: 20px;

  &.disabled {
    background: var(--btnPrimaryDisabled);
    color: var(--btnDisabledText);
    cursor: default;
    pointer-events: none;
  }
}
</style>
