var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isExpandCardMode)?_c('div',[(_vm.careFlags.length > 0)?_c('aq-care-flag',{attrs:{"care-flags":_vm.careFlags}}):_vm._e(),_c('item-heading',{staticClass:"gap-m",attrs:{"heading":_vm.userHeading,"heading-class":"mb-1 user-name text-truncate","paragraph":_vm.customerAddress,"paragraph-class":"text-truncate"}},[_c('router-link',{attrs:{"to":{ name: 'customer', params: { customerId: _vm.user.id } },"exact-active-class":"current-route"}},[_c('aq-icon-avatar',{staticClass:"customer-icon cursor-inherit",attrs:{"value":[_vm.user.firstName, _vm.user.lastName]}},[_c('div',{staticClass:"aqv-customer"})]),(_vm.requireSpecialLicensing(_vm.currentContact.address.regionCounty))?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
					content: 'Licensed permissions state',
					classes: 'tooltip-orange'
				}),expression:"{\n\t\t\t\t\tcontent: 'Licensed permissions state',\n\t\t\t\t\tclasses: 'tooltip-orange'\n\t\t\t\t}"}],staticClass:"fas fa-exclamation-triangle text-warning"}):_vm._e()],1),_c('template',{slot:"rightArea"},[_c('div',{staticClass:"right-area"},[(_vm.contacts.length > 1)?_c('aq-list-items-popover',{attrs:{"label":"Switch contact","items":_vm.contacts,"item-label":(item) => `${item.firstName} ${item.lastName}`,"selected-item":_vm.currentContact,"hide-on-select":true},on:{"input":_vm.onContactSelected}},[_c('ContactSwitcher',{staticClass:"contact-switcher"})],1):_vm._e()],1)]),_c('template',{slot:"besideHeadingArea"},[_c('div',{staticClass:"d-flex"},[(this.currentContact.id !== this.user.id)?_c('div',{staticClass:"named-contact-label"},[_vm._v(" (Named contact) ")]):_vm._e()])])],2),_c('div',{staticClass:"attributes"},[_c('toggle-card-info',{attrs:{"attributes":_vm.attributes,"rows":2,"columns":3}})],1),_c('div',{staticClass:"d-flex align-items-center mt-16"},[_c('aq-brand-image-avatar',{staticClass:"mx-4",attrs:{"brand-name":_vm.user.brandName,"brand-id":_vm.user.brandId}})],1)],1):_c('div',[(_vm.careFlags.length > 0)?_c('aq-care-flag',{attrs:{"care-flags":_vm.careFlags}}):_vm._e(),_c('item-heading',{staticClass:"gap-m",attrs:{"heading":_vm.userHeading,"heading-class":"mb-1 user-name text-truncate","paragraph":_vm.customerAddress,"paragraph-class":"text-truncate"}},[_c('router-link',{attrs:{"to":{ name: 'customer', params: { customerId: _vm.user.id } },"exact-active-class":"current-route"}},[_c('aq-icon-avatar',{staticClass:"customer-icon cursor-inherit",attrs:{"value":[_vm.user.firstName, _vm.user.lastName]}},[_c('div',{staticClass:"aqv-customer"})]),(_vm.requireSpecialLicensing(_vm.currentContact.address.regionCounty))?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
					content: 'Licensed permissions state',
					classes: 'tooltip-orange'
				}),expression:"{\n\t\t\t\t\tcontent: 'Licensed permissions state',\n\t\t\t\t\tclasses: 'tooltip-orange'\n\t\t\t\t}"}],staticClass:"fas fa-exclamation-triangle text-warning"}):_vm._e()],1),_c('template',{slot:"rightArea"},[_c('div',{staticClass:"right-area collapsed"},[_c('aq-brand-image-avatar',{attrs:{"brand-name":_vm.user.brandName,"brand-id":_vm.user.brandId}}),(_vm.contacts.length > 1)?_c('aq-list-items-popover',{attrs:{"label":"Switch contact","items":_vm.contacts,"item-label":(item) => `${item.firstName} ${item.lastName}`,"selected-item":_vm.currentContact,"hide-on-select":true},on:{"input":_vm.onContactSelected}},[_c('ContactSwitcher',{staticClass:"contact-switcher"})],1):_vm._e()],1)]),_c('template',{slot:"besideHeadingArea"},[_c('div',{staticClass:"d-flex"},[(this.currentContact.id !== this.user.id)?_c('div',{staticClass:"named-contact-label"},[_vm._v(" (Named contact) ")]):_vm._e()])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }