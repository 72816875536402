import Vue from 'vue';
import Vuex from 'vuex';
import NotificationsService from '@commonServices/notificationsService';
import SettingsService from '@commonServices/settingsService';
import workflowService from '@commonServices/workflowService';
import extraService from '@commonServices/extraService';
import UsersService from '@commonServices/usersService';
import { StorageKeyPersistentStore } from '@commonServices/utils/constants';
import { addDays } from '@commonServices/utils/dateUtils';
import { isObject } from '@commonServices/utils/general';
import { TaskViewModeEnum } from '@commonServices/settings/taskViewSettings';
import { RecentlyViewedModeEnum } from '@commonServices/settings/recentlyViewedSettings';
import ClaimViewMode from '@commonServices/settings/ClaimViewMode';
import persistentStorage from '@commonServices/utils/persistentStorage';
import GraphViewMode from './services/settings/GraphViewMode';
import { USER_STATE_PROPS } from '@constants/userState';
import CardViewMode from './services/settings/CardViewMode';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		currentUser: null,
		isSearchButtonClicked: false,
		interactionContext: {
			customer: null,
			pet: null,
			claim: {
				id: null,
				isReadOnly: null,
				registeredVets: null,
				continuation: null,
			},
			brand: {},
		},
		claimPolicyId: 0,
		isOpenDocPanel: false,
		isAnyBasicFieldsPopulated: false,
		notificationsCount: 0,
		appSettings: {},
		tasks: {},
		basicClaimAmount: 0,
		currentClaimStepName: null,
		currentClaimStatus: null,
		calculateClaimItemsOnPanelClose: false,
		assignedTasks: [],
		brand: {},
		themeIconSettings: null,
		claimPayees: [],
		bodyParts: [],
		lossTypes: [],
		levelFilter: {
			customerId: null,
			petId: null,
			claimId: null,
			policyIds: null,
		},
		claimStepState: {},
		stateLoaded: false,
		persistentStore: { },
		claimFormType: null,
		draftData: {},
	},
	mutations: {
		setThemeIconSettings (state, setting) {
			state.themeIconSettings = setting;
		},
		updateCurrentUser (state, user) {
			state.currentUser = user;
		},
		triggerSearchButtonClick (state) {
			state.isSearchButtonClicked = !state.isSearchButtonClicked;
		},
		setInteractionContext (state, { customer, pet, claim }) {
			// undefined check is required because of object destruction usage
			// if customer, pet, claim is not passed - do not update the value
			// if null is passed - reset the value
			if (typeof customer !== 'undefined') {
				if (customer == null) {
					state.interactionContext.customer = null;
					state.brand = {};
				} else {
					const { id, firstName, lastName, brandId, brandName, brandLocale, multicondition, brandSuppressMissingInfo, brandManualStopReissue, billingSyncEnabled } = customer;
					state.interactionContext.customer = { id, firstName, lastName };
					state.brand = { brandId, brandName, brandLocale, multicondition, brandSuppressMissingInfo, brandManualStopReissue, billingSyncEnabled };
				}
			}
			if (typeof pet !== 'undefined') {
				state.interactionContext.pet = pet == null ? null : { ...pet, dateOfDeath: pet.dateOfDeath, birthDate: pet.birthDate };
			}
			if (typeof claim !== 'undefined') {
				state.interactionContext.claim = claim;
			}
			const levelFilter = {
				customerId: null,
				petId: null,
				claimId: null,
				policyIds: null,
			};

			if (state.interactionContext.claim !== null) {
				levelFilter.claimId = state.interactionContext.claim.id;
			} else if (state.interactionContext.pet !== null) {
				levelFilter.petId = state.interactionContext.pet.id;
			} else if (state.interactionContext.customer !== null) {
				levelFilter.customerId = state.interactionContext.customer.id;
			};
			state.levelFilter = levelFilter;
		},
		setClaimPolicyId (state, newValue) {
			state.claimPolicyId = newValue;
		},
		toggleDocPanel (state, newValue) {
			state.isOpenDocPanel = newValue;
		},
		setIsAnyBasicFieldsPopulated (state, newValue) {
			state.isAnyBasicFieldsPopulated = newValue;
		},
		setNotificationsCount (state, newValue) {
			state.notificationsCount = newValue;
		},
		setBodyParts (state, newValue) {
			state.bodyParts = newValue;
		},
		setLossTypes (state, newValue) {
			state.lossTypes = newValue;
		},
		setSettings (state, settings) {
			state.appSettings = settings;
		},
		setTaskData (state, taskData) {
			Vue.set(state.tasks, taskData.id, { ...state.tasks[taskData.id], ...taskData });
		},
		setBasicClaimAmount (state, value) {
			state.basicClaimAmount = value;
		},
		setCurrentClaimStepName (state, claimStepName) {
			state.currentClaimStepName = claimStepName;
		},
		setCurrentClaimStatus (state, claimStatus) {
			state.currentClaimStatus = claimStatus;
		},
		setCalculateClaimItemsOnPanelClose (state, value) {
			state.calculateClaimItemsOnPanelClose = value;
		},
		setAssignedTasks (state, tasks) {
			state.assignedTasks = tasks;
		},
		setClaimPayees (state, payees) {
			state.claimPayees = payees;
		},
		setClaimRegisteredVets (state, vets) {
			state.interactionContext.claim.registeredVets = vets;
		},
		setLevelFilter (state, levelFilter) {
			state.levelFilter = { customerId: null, petId: null, claimId: null, policyIds: null, ...levelFilter };
		},
		setClaimStepState (state, claim) {
			state.claimStepState = claim;
		},
		setPersistentStore (state, persistentStore) {
			state.persistentStore = persistentStore;
			state.stateLoaded = true;
		},
		setClaimFormType (state, claimFormType) {
			state.claimFormType = claimFormType;
		},
		setDraftData (state, draftData) {
			state.draftData = draftData;
		},
	},
	actions: {
		changeThemeIconSettings ({ commit }, settings) {
			commit('setThemeIconSettings', settings);
		},
		changeInteractionContext ({ commit }, { customer, pet, claim }) {
			commit('setInteractionContext', { customer, pet, claim });

			if (claim?.status) {
				commit('setCurrentClaimStatus', claim.status);
			}
		},
		resetInteractionContext ({ commit }) {
			commit('setInteractionContext', { customer: null, pet: null, claim: null });
		},
		changeClaimPolicy ({ commit }, newId) {
			commit('setClaimPolicyId', newId);
		},
		changeIsAnyBasicFieldsPopulated ({ commit }, newValue) {
			commit('setIsAnyBasicFieldsPopulated', newValue);
		},
		async setNotificationsCountAsync ({ commit }) {
			const count = await NotificationsService.getUnreadNotificationsCount();
			commit('setNotificationsCount', count);
		},
		async loadBodyParts ({ commit }) {
			if (this.state.bodyParts.length === 0) {
				const bodyParts = await extraService.getBodyParts();
				commit('setBodyParts', bodyParts);
			}
		},
		async loadLossTypes ({ commit }) {
			if (this.state.lossTypes.length === 0) {
				const lossTypes = await extraService.getLossTypes();
				commit('setLossTypes', lossTypes);
			}
		},
		changeTaskData ({ commit }, taskData) {
			commit('setTaskData', taskData);
		},
		changeBasicClaimAmount ({ commit }, basicClaimAmount) {
			commit('setBasicClaimAmount', basicClaimAmount);
		},
		changeCurrentClaimStepName ({ commit }, claimStepName) {
			commit('setCurrentClaimStepName', claimStepName);
		},
		changeCurrentClaimStatus ({ commit }, claimStatus) {
			commit('setCurrentClaimStatus', claimStatus);
		},
		changeCalculateClaimItemsOnPanelClose ({ commit }, value) {
			commit('setCalculateClaimItemsOnPanelClose', value);
		},
		setClaimPayees ({ commit }, payees) {
			commit('setClaimPayees', payees);
		},
		async loadAssignedTasks ({ commit }) {
			const items = await workflowService.getTasks();
			commit('setAssignedTasks', items);
		},
		setClaimRegisteredVets ({ commit }, vets) {
			commit('setClaimRegisteredVets', vets);
		},
		setLevelFilter ({ commit }, levelFilter) {
			commit('setLevelFilter', levelFilter);
		},
		async loadSettings ({ commit }) {
			const settings = await SettingsService.getAppSettings();
			commit('setSettings', settings);
		},
		async loadUserData ({ commit }) {
			const userData = await UsersService.getCurrentUser();
			commit('updateCurrentUser', userData);
		},
		// state update actions
		async loadUserState ({ commit }) {
			// load current remote storage state
			const currentUserState = await UsersService.getUserState();
			commit('setPersistentStore', currentUserState);
		},
		async saveUserState ({ commit }, data) {
			const currentUserState = await UsersService.saveUserState(data);
			commit('setPersistentStore', currentUserState);
		},
		setUserOptIn ({ dispatch }, userOptIn) {
			dispatch('updatePersistentStoreProp', { prop: USER_STATE_PROPS.USER_OPT_IN, payload: userOptIn });
		},
		setCurrentPolicyTermGroup ({ dispatch }, groupData) {
			dispatch('updatePersistentStoreProp', { prop: USER_STATE_PROPS.SELECTED_POLICY_TYPE, payload: { [groupData.petId]: groupData.policyTermGroup } });
		},
		setShortKeyEnabled ({ dispatch }, shortKeyEnabled) {
			dispatch('updatePersistentStoreProp', { prop: USER_STATE_PROPS.SHORTKEY_ENABLED, payload: shortKeyEnabled });
		},
		updateTaskViewMode ({ dispatch }, mode) {
			dispatch('updatePersistentStoreProp', { prop: USER_STATE_PROPS.TASK_VIEW_MODE, payload: mode });
		},
		updateRecentlyViewedMode ({ dispatch }, mode) {
			dispatch('updatePersistentStoreProp', { prop: USER_STATE_PROPS.RECENTLY_VIEWED_MODE, payload: mode });
		},
		updateClaimViewMode ({ dispatch }, mode) {
			dispatch('updatePersistentStoreProp', { prop: USER_STATE_PROPS.CLAIM_VIEW_MODE, payload: mode });
		},
		updateGraphViewMode ({ dispatch }, mode) {
			dispatch('updatePersistentStoreProp', { prop: USER_STATE_PROPS.GRAPH_VIEW_MODE, payload: mode });
		},
		updateCardViewMode ({ dispatch }, mode) {
			dispatch('updatePersistentStoreProp', { prop: USER_STATE_PROPS.CARD_VIEW_MODE, payload: mode });
		},
		saveTasksFilter ({ dispatch }, filters) {
			dispatch('updatePersistentStoreProp', { prop: USER_STATE_PROPS.TASKS_FILTER, payload: filters });
		},
		updatePersistentStoreProp ({ commit, dispatch, state }, update) {
			const { prop, payload } = update;
			const persistentStore = { ...state.persistentStore };
			if (isObject(payload)) {
				const propState = persistentStore[prop] ?? {};
				persistentStore[prop] = { ...propState, ...payload };
			} else {
				persistentStore[prop] = payload;
			}
			dispatch('saveUserState', persistentStore);
			persistentStorage.set(StorageKeyPersistentStore, {}); // VIS-29936. Cleanup first. Will be remove later with next release as not needed anymore
		},
		clearDraftData ({ commit }, { draftName, key }) {
			const draftData = { ...this.state.draftData };
			delete draftData[`${draftName}_${key}`];
			commit('setDraftData', draftData);
		},
		addDraftData ({ commit, state }, { draftName, key, data }) {
			const draftData = { ...state.draftData };
			draftData[`${draftName}_${key}`] = data;
			commit('setDraftData', draftData);
		},
		initialize ({ dispatch }) {
			dispatch('loadUserState');
			dispatch('loadSettings');
			dispatch('loadUserData');
			dispatch('loadAssignedTasks');
		},
	},
	getters: {
		dateOfLossLabel: state => state.appSettings.dateOfLossLabel ?? 'Date of Loss',
		isCustomerContext: state => state.interactionContext.customer != null && state.interactionContext.pet == null && state.interactionContext.claim == null,
		isPetContext: state => state.interactionContext.pet != null && state.interactionContext.claim == null,
		isClaimContext: state => state.interactionContext.claim != null,
		getSelectedPolicyType: state => (petId) => (state.persistentStore[USER_STATE_PROPS.SELECTED_POLICY_TYPE] ?? {})[petId],
		taskViewMode: state => state.persistentStore[USER_STATE_PROPS.TASK_VIEW_MODE] ?? TaskViewModeEnum.Card,
		recentlyViewedMode: state => state.persistentStore[USER_STATE_PROPS.RECENTLY_VIEWED_MODE] ?? RecentlyViewedModeEnum.Customers,
		claimViewMode: state => state.persistentStore[USER_STATE_PROPS.CLAIM_VIEW_MODE] ?? ClaimViewMode.Group,
		graphViewMode: state => state.persistentStore[USER_STATE_PROPS.GRAPH_VIEW_MODE] ?? GraphViewMode.Open,
		userOptIn: state => state.persistentStore[USER_STATE_PROPS.USER_OPT_IN] ?? true,
		shortKeyEnabled: state => state.persistentStore[USER_STATE_PROPS.SHORTKEY_ENABLED] ?? true,
		displayCoInsurancePayout: state => state.appSettings.displayCoInsurancePayout ?? false,
		stateLoaded: state => state.stateLoaded,
		dateForFutureValidation: state => addDays(new Date(), state.appSettings.extendedDateInFutureValidation ? 1 : 0),
		requireSpecialLicensing: state => (customerRegionCounty) => state.appSettings.regulatorySettings.StatesSpecialLicense?.some(regionCounty => regionCounty === customerRegionCounty),
		isOpenClaimContinuation: state => state.interactionContext.claim?.continuation,
		getDraftData: state => (draftName, key) => state.draftData[`${draftName}_${key}`],
		isExpandCardMode: state => (state.persistentStore[USER_STATE_PROPS.CARD_VIEW_MODE] ?? CardViewMode.Collapsed) === CardViewMode.Expanded,
		autoAssignedTasks: state => state.assignedTasks.filter(x => x.prioritizedWorkQueue),
		tasksFilter: state => (state.persistentStore[USER_STATE_PROPS.TASKS_FILTER]) ?? {},
	},
});

export default store;
